var React = require('react')
var ReactDom = require('react-dom')

import PictureBlurb from '../comps/pictureBlurb.js'
import SkillsBlurb from '../comps/skillsBlurb.js';
import ExperienceCard from '../comps/experienceCard.js'
import ContactMe from '../comps/contactMe.js'
import registerServiceWorker from './pwa/registerServiceWorker.js';

import pictureHeadshot from '../media/john_craig.png'
import Logo1 from '../media/Kubra-Logo.jpg'
import Logo3 from '../media/CSB-Logo.png'
import Logo2 from '../media/SignalUX-logo.jpg'
import './app.css'

class App extends React.Component{

    constructor(props) {
        super(props);
        this.kubraBlurb = "During my time at Kubra, I have used my technical skills and coding knowledge to spearhead front-end and back-end projects using the .NET framework. Notable projects I have worked on have included managing and creating API endpoints, implementing server logs and performing server maintenance, adding new features to key customer applications, and completing full webpage redesigns for clients. In this position, I have participated daily in team code review and I have used git workflow techniques to ensure all of my code changes meet internal compliance standards."
        this.kubraSkills = ["Javascript","ASP.NET","CSS/HTML","C#","VB.NET","SQL","Git"]
        this.kubraLink = "https://kubra.com/"
        this.kubraAlt = "Kubra Logo"
        this.signalUXBlurb = "While working for SignalUX I created and applied a heuristic algorithm in Python to solve a 2D cutting stock problem. I then used Python’s Django library to build and publish my algorithm for commercial use."
        this.signalUXSkills = ["Python","Django","CSS/HTML","Git"]
        this.signalUXLink = "https://www.readysetgo.design/"
        this.signalUXAlt = "SignalUX Logo"
        this.CSBBlurb = "At CSB I designed and implemented statistical models using machine learning and other analysis techniques for customer segmentation applications. I completed all data cleaning, modelling, and visualization using Python (notably the Pandas and MatPlotLib libraries), and I managed and queried for data using SQL."
        this.CSBSkills = ["Python","SQL"]
        this.CSBLink = "https://canpl.ca/"
        this.CSBAlt = "CSB Logo"
        this.state = {
            visible: "1",
            loc: 0,
            mouseBeingUsedClass: '',
            mouseClickedInTextArea:false
        };
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleEvent, true);
        window.addEventListener('mouseup', this.handleEvent, true);
        window.addEventListener("keydown", this.handleEvent, true);
    }

    handleEvent = (e) => {
        //If you click using a mouse
        if(e.type == 'mousedown' && this.state.mouseBeingUsedClass == ''){
            this.setState({
                mouseBeingUsedClass:'mouse'
            })
        }
        //If you use keys outside of a text input
        if(e.type == 'keydown' && this.state.mouseBeingUsedClass == 'mouse' && e.target.tagName!='INPUT' && e.target.tagName!='TEXTAREA' && e.key != 'Shift' && e.key != 'Control'){
            this.setState({
                mouseBeingUsedClass:''
            })
        }
        //If you click inside an input area
        if(e.type == 'mousedown' && this.state.mouseBeingUsedClass == 'mouse' && (e.target.tagName=='INPUT' || e.target.tagName=='TEXTAREA') ){
            this.setState({
                mouseClickedInTextArea:true
            })
        }else if(e.type == 'mousedown' && this.state.mouseBeingUsedClass == 'mouse'){
            this.setState({
                mouseClickedInTextArea:false
            })
        }
        
        //Clear active after a click is made so that hover can still be applied
        if(e.type =='mouseup' && this.state.mouseBeingUsedClass == 'mouse' && this.state.mouseClickedInTextArea==false){
            document.activeElement.blur()
        }
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    sendEmail = async(name, email, message, termsAndConditions)=>{
        const response = await fetch('/send_email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name:name, email:email, message:message, termsAndConditions:termsAndConditions }),
          });
          const body = await response.text();

          if(body.toString() != ""){
              this.setState({visible:"0"});
              await this.timeout(2000);
              this.setState({loc:-10000});
          }
    }

    openLink = (link) =>{
        const a = document.createElement('a');
        a.setAttribute('hidden','');
        a.setAttribute('href', link);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    openLinkNewTab = (link) =>{
        window.open(link, "_blank")
    }

    render(){
        registerServiceWorker();
        return(
            <div 
                id='appContents' 
                className={'color-background_primary color-text_primary_dark '+this.state.mouseBeingUsedClass}
                style={{overflowY:'hidden'}}
                role="main">
                <PictureBlurb 
                    id='pictureContainer' 
                    image={pictureHeadshot}/>
                <div 
                    id='skillsContainer'
                    className='color-background_secondary color-text_light'>
                    <SkillsBlurb/>
                </div>
                <ExperienceCard 
                    Company='Kubra' 
                    Title='Programmer/Analyst'
                    Logo = {Logo1}
                    jobBlurb={this.kubraBlurb}
                    Skills = {this.kubraSkills}
                    Link = {this.kubraLink}
                    openLink = {this.openLinkNewTab}
                    altText = {this.kubraAlt}/>
                <ExperienceCard
                    Company='SignalUX'
                    Title='Software Engineer'
                    Logo = {Logo2}
                    jobBlurb={this.signalUXBlurb}
                    Skills = {this.signalUXSkills}
                    Link = {this.signalUXLink}
                    openLink = {this.openLinkNewTab}
                    altText = {this.signalUXAlt}/>
                <ExperienceCard
                    Company='CSB'
                    Title='Data Analyst'
                    Logo = {Logo3}
                    jobBlurb={this.CSBBlurb}
                    Skills = {this.CSBSkills}
                    Link = {this.CSBLink}
                    openLink = {this.openLinkNewTab}
                    altText = {this.CSBAlt}/>
                <ContactMe 
                    sendEmail = {this.sendEmail}
                    visible = {this.state.visible}
                    loc = {this.state.loc}
                    openLink = {this.openLink}
                    openLinkNewTab = {this.openLinkNewTab}/>
            </div>
        )
    }
}

ReactDom.render(<App/>,document.getElementById("app"))