var React = require('react')

import emailIcon from '../media/emailIcon.png'
import phoneIcon from '../media/phoneIcon.png'
import linkedinIcon from '../media/linkedinIcon.png'

class ContactMe extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            name:"",
            email:"",
            message:"",
            termsAndConditions:false,
            nameColor:"1px #bbb solid",
            nameTextColor:"var(--secondary_dark)",
            emailColor:"1px #bbb solid",
            emailTextColor:"var(--secondary_dark)",
            messageColor:"1px #bbb solid",
            messageTextColor:"var(--secondary_dark)",
            doubleClick:false
        }
    }

    validate(){

        var validator = true;

        if (this.state.doubleClick == true)
            return(false)

        if(this.state.name == ""){
            this.setState({nameColor:"1px red solid"})
            this.setState({nameTextColor:"red"})
            validator = false;
        }else{
            this.setState({nameColor:"1px #bbb solid"})
            this.setState({nameTextColor:"var(--secondary_dark)"})
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!re.test(this.state.email) || this.state.email == ""){
            this.setState({emailColor:"1px red solid"})
            this.setState({emailTextColor:"red"})
            validator = false;
        }else{
            this.setState({emailColor:"1px #bbb solid"})
            this.setState({emailTextColor:"var(--secondary_dark)"})
        }

        if(this.state.message == ""){
            this.setState({messageColor:"1px red solid"})
            this.setState({messageTextColor:"red"})
            validator = false;
        }else{
            this.setState({messageColor:"1px #bbb solid"})
            this.setState({messageTextColor:"var(--secondary_dark)"})
        }

        //TODO add frontend cleaning
        if(this.state.name != "" && this.state.email != ""&& this.state.message != "" && validator=="true"){
            this.setState({nameColor:"1px #4b8b00 solid"})
            this.setState({nameTextColor:"#4b8b00"})
            this.setState({emailColor:"1px #4b8b00 solid"})
            this.setState({emailTextColor:"#4b8b00"})
            this.setState({messageColor:"1px #4b8b00 solid"})
            this.setState({messageTextColor:"#4b8b00"})
        }
        
        return(validator);
    };

    handleChange = ({ target }) => {
        if(target.name != "termsAndConditions"){
            this.setState({ [target.name]: target.value });
            console.log({ [target.name]: target.value });
        }else{
            this.setState({ [target.name]: target.checked });
            console.log({ [target.name]: target.checked });
        }
    };

    handleClick = () =>{
        if (this.validate()){
            this.setState({
                doubleClick:true
            })
            this.props.sendEmail(
                this.state.name,
                this.state.email,
                this.state.message,
                this.state.termsAndConditions);
        }
    };

    render(){
        return(
            <div
                id="contactMe"
                className="container">
                <div 
                    className="flex-column color-background_primary padding_vertical50"
                    style={{paddingBottom:'0px'}}>
                    <div 
                        className = 'flex-row flex_spaceCenter margin_vertical50' 
                        style = {{marginTop:"0px"}}>
                        <div 
                            className = 'color-dominant padding10 rcorners10 color-text_light'>
                            <h1>Let's talk.</h1>
                        </div>
                    </div>
                    <div 
                        className = 'flexSwitch_StarttoCenter flex-row flex_stretch'>
                        <div 
                            className = 'flex-column margin_horizontal4 flex_spaceBetween heightContactButtons' 
                            style = {{flex:1}}>
                            <button 
                                className = 'flexSwitch_SpaceStarttoCenter hoverButton flex-row flex_center margin_horizontal8 color-text_input'
                                style = {{'marginTop':'30px', 'marginBottom':'0'}}
                                onClick={() => this.props.openLink("mailto:johnpatrickcraig@outlook.com")}>
                                <img 
                                    src={emailIcon}
                                    className='image_icon'
                                    alt='Email Logo'></img>
                                <p 
                                    style={{ marginLeft:'4%'}}>
                                    johnpatrickcraig@outlook.com</p>
                            </button>
                            <button 
                                className = 'flexSwitch_SpaceStarttoCenter hoverButton flex-row flex_center margin_horizontal8  color-text_input' 
                                style = {{'marginTop':'0', 'marginBottom':'0'}}
                                onClick={() => this.props.openLink("tel:613-893-1351")}>
                                <img 
                                    src={phoneIcon}
                                    className='image_icon'
                                    alt='Phone Logo'></img>
                                <p 
                                    style={{ marginLeft:'4%'}}>
                                    613-893-1351</p>
                            </button>
                            <button 
                                className = 'flexSwitch_SpaceStarttoCenter hoverButton flex-row flex_center margin_horizontal8 color-text_input'
                                style = {{'marginTop':'0', 'marginBottom':'0'}}
                                onClick={() => this.props.openLinkNewTab("https://www.linkedin.com/in/johnpatrickcraig/")}>
                                <img 
                                    src={linkedinIcon} 
                                    className='image_icon'
                                    alt='Linkedin Logo'></img>
                                <p 
                                    style={{ marginLeft:'4%'}}>
                                    Message me on LinkedIn</p>
                            </button>
                            <button 
                                className = 'flexSwitch_SpaceStarttoCenter hoverButton flex-row flex_center margin_horizontal8 color-text_input contactButtonsBottom'
                                style = {{'marginTop':'0'}}
                                onClick={() => this.props.openLinkNewTab("https://github.com/JCraigWasTaken")}>
                                <div 
                                    className='devicons devicons-github_badge image_icon'></div>
                                <p 
                                    style={{ marginLeft:'4%'}}>
                                    Check out my Github</p>
                            </button>                          
                        </div>
                        <div 
                            className = 'margin_horizontal6 smallVanish1000'
                            style = {{flex:1, position:'relative', height:'437px'}}>
                            <div
                                className = 'flex-column messageSendTransition'
                                style = {{transform:"translateX("+this.props.loc+"px)", zIndex:'1', opacity:this.props.visible.toString(), marginBottom:'25px'}}>
                                <p
                                    style={{color:this.state.nameTextColor}}>
                                    Name</p>
                                <label>
                                    Name
                                    <input 
                                        id='nameTextArea'
                                        name='nameTextArea'
                                        type="text"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Mats Sundin"
                                        value={this.state.name}
                                        name='name'
                                        style={{border:this.state.nameColor}}
                                        maxLength="100"
                                        label="Name"/>
                                </label>
                                <p 
                                    style={{color:this.state.emailTextColor}}>
                                    Email</p>
                                <label>
                                    Email
                                    <input 
                                        id='emailTextArea'
                                        name='emailTextArea'
                                        type="email"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="mapleleafs67@gmail.com"
                                        value={this.state.email}
                                        name='email'
                                        style={{border:this.state.emailColor}}
                                        maxLength="65"
                                        label="Email"/>
                                </label>
                                <p 
                                    style={{color:this.state.messageTextColor}}>
                                    Message</p>
                                <label>
                                    Message
                                    <textarea
                                        id='messageTextArea'
                                        name='messageTextArea'
                                        rows="5"
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder="Hello..."
                                        value={this.state.message}
                                        name='message'
                                        style={{border:this.state.messageColor}}
                                        spellCheck="false"
                                        maxLength="1000"/>
                                </label>
                                <input
                                    type="checkbox"
                                    onChange={(e) => this.handleChange(e)}
                                    checked={this.state.termsAndConditions}
                                    tabIndex="-1"
                                    autoComplete="false"
                                    name="termsAndConditions"/>
                                <button 
                                    id='sendButton'
                                    name='sendButton'
                                    onClick={(e) => this.handleClick(e)}
                                    className="hoverButton">Send</button>
                            </div>
                            <div
                                className = 'flex-column messageSendTransition'
                                style = {{opacity:(1-this.props.visible).toString(), marginTop:26}}>
                                <div 
                                className = 'flex-row flex_spaceCenter margin_vertical25' 
                                style = {{marginTop:"0px"}}>
                                    <div 
                                        className = 'color-dominant padding10 rcorners10 color-text_light'>
                                        <h2>Thank you for getting in touch!</h2>
                                    </div>
                                </div>
                                <p className='color-text_secondary_dark'>I will respond to your message promptly. If you would like to connect with me through other means feel free to call me, email me, or send me a message on Linkedin using the buttons to the left.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}

export default ContactMe;