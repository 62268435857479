var React = require('react')

class PictureBlurb extends React.Component{
    render(){
        return(
            <div 
                id='imageBlurb' 
                className = 'container'>
                <div 
                    className='flexSwitch_HtoV margin_vertical100 flex_spaceBetween margin_horizontal4'>
                    <div 
                        className='flexSwitch_HtoV flex_spaceBetween needed6'>
                        <img 
                            src = {this.props.image} 
                            className='image_largeSquare margin_horizontal2'
                            alt='John Craig Headshot'></img>
                        <div 
                            className='titlePosition-text color-dominant margin_horizontal2 color-text_light margin_vertical50 rcorners10 needed1'>
                            <h1>John Craig</h1>
                            <h2>Fullstack Engineer | Web Dev</h2>
                        </div>
                    </div>
                    <div 
                        className='flex-column flex_spaceEvenly margin_horizontal2 needed3'>
                        <p>I am a Programmer/Analyst at Kubra with a passion for innovative design and problem-solving. I have a BSc in Mechanical Engineering from Queen’s University and I am currently based in Toronto, Canada. If I’m not programming, some of my hobbies include skiing, rock climbing, and building robots.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default PictureBlurb;