var React = require('react')
import ReactLogo from '../media/react.svg'
import CSharpLogo from '../media/csharp-plain.svg'

class SkillsBlurb extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            currentActive: 'logo1Active',
            logo1Active: 'divAnimationBackground divAnimation rcorners10',
            logo2Active: 'divAnimationBackground rcorners10',
            logo3Active: 'divAnimationBackground rcorners10',
            logo4Active: 'divAnimationBackground rcorners10',
            logo5Active: 'divAnimationBackground rcorners10',
            logo6Active: 'divAnimationBackground rcorners10',
            logo7Active: 'divAnimationBackground rcorners10',
            logo8Active: 'divAnimationBackground rcorners10',
            logo9Active: 'divAnimationBackground rcorners10',
            currentRow: 1,
        };
    }
    
    componentDidMount(){
        this.intervalID = setInterval(
            () => this.tick(),
            2000
        );
    }

    tick() {
        var newActive = 'logo'+(Math.ceil(Math.random()*3)+3*this.state.currentRow)+'Active';
        var newRow = 0;
        if (this.state.currentRow != 2){
            newRow = this.state.currentRow+1;
        }else{
            newRow = 0;
        }
        this.setState({
            [this.state.currentActive]: 'divAnimationBackground rcorners10',
            [newActive]: 'divAnimationBackground divAnimation rcorners10',
            currentActive: newActive,
            currentRow: newRow
        });
    }

    render(){
        return(
            <div 
                id='skillsBlurb'
                className = 'container color-background_secondary color-text_light padding_vertical100'
                style={{paddingBottom:'50px'}}>
                <div 
                    className='flexSwitch_HtoV flex_spaceBetween flex_center margin_horizontal4' 
                    style={{flex:3}}>
                    <div 
                        id='row1-mobile'
                        className='flex-row flex_spaceEvenly margin_horizontal2 margin_vertical25 largeVanish'
                        style={{'marginTop':'0px', 'width':'100%'}}>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-javascript_badge dIconsImage_smallSquare'></div>
                            <div 
                                className={this.state.logo1Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-dotnet dIconsImage_smallSquare'></div>
                            <div 
                                className={this.state.logo2Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-msql_server dIconsImage_smallSquare'></div>
                            <div
                                className={this.state.logo3Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-python dIconsImage_smallSquare'></div>
                            <div
                                className={this.state.logo4Active}></div>
                            </div>
                        <div
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-css3 dIconsImage_smallSquare'></div>
                            <div
                                className={this.state.logo5Active}></div>
                        </div>
                    </div>
                    <div 
                        id='row2-mobile'
                        className='flex-row flex_spaceEvenly margin_horizontal2 margin_vertical25 largeVanish'
                        style={{'marginTop':'0px', 'width':'100%'}}>
                        <div 
                            className='divAnimationContainer'>
                            <img 
                                src={ReactLogo}
                                className='image_smallSquare'
                                alt='React Logo'></img>
                            <div 
                                className={this.state.logo6Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-git dIconsImage_smallSquare'></div>
                            <div 
                                className={this.state.logo7Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-nodejs dIconsImage_smallSquare'></div>
                            <div
                                className={this.state.logo8Active}></div>
                        </div>
                        <div 
                            className='divAnimationContainer'>
                            <img 
                                src={CSharpLogo}
                                className='image_smallSquare'
                                alt='CSharp Logo'></img>
                            <div
                                className={this.state.logo9Active}></div>
                            </div>
                        <div
                            className='divAnimationContainer'>
                            <div 
                                className='devicons devicons-html5 dIconsImage_smallSquare'></div>
                            <div></div>
                        </div>
                    </div>
                    <div 
                        className = 'margin_horizontal2'
                        style={{flex:6}}>
                        <p>I have taught myself a wide variety of skills while transitioning from my training in mechanical engineering to a career in software development. My front-end experience has been with ASP.NET MVC and React, both of which I have used while building desktop and mobile friendly web apps for personal and professional use. I have developed back-end solutions for clients using C#, VB.NET, Python, and Node.js, many of which are currently in commercial use. I have professional experience using git workflow techniques with teams for code review and version control. I also have practiced knowledge writing queries as well as creating and editing stored procedures for production applications using SQL Server. I built this website using my own personal Express-React-Node boilerplate, it can be found here:
                        <br/>
                        <br/>
                        </p>
                        <a href='https://github.com/JCraigWasTaken/ERN-Heroku-Boilerplate' className='color-text_light'>https://github.com/JCraigWasTaken/ERN-Heroku-Boilerplate</a>
                        <p>
                        <br/>
                        The boilerplate, including the webpack/babel setup was made by me from the ground up.</p>
                    </div>
                    <div 
                        id='3grid'
                        className = 'flex-column flex_spaceEvenly margin_horizontal2 smallVanish'
                        style={{height:'300px', flex:3}}>
                        <div 
                            id='row1'
                            className = 'flex-row flex_spaceBetween'>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-javascript_badge dIconsImage_smallSquare'></div>
                                <div 
                                    className={this.state.logo1Active}></div>
                            </div>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-dotnet dIconsImage_smallSquare'></div>
                                <div 
                                    className={this.state.logo2Active}></div>
                            </div>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-msql_server dIconsImage_smallSquare'></div>
                                <div
                                    className={this.state.logo3Active}></div>
                            </div>
                        </div>
                        <div 
                            id='row2'
                            className = 'flex-row flex_spaceBetween'>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-python dIconsImage_smallSquare'></div>
                                <div
                                    className={this.state.logo4Active}></div>
                            </div>
                            <div
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-css3 dIconsImage_smallSquare'></div>
                                <div
                                    className={this.state.logo5Active}></div>
                            </div>
                            <div
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-nodejs dIconsImage_smallSquare'></div>
                                <div
                                    className={this.state.logo6Active}></div>
                            </div>
                        </div>
                        <div 
                            id='row3'
                            className = 'flex-row flex_spaceBetween'>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-git dIconsImage_smallSquare'></div>
                                <div 
                                    className={this.state.logo7Active}></div>
                            </div>
                            <div 
                                className='divAnimationContainer'>
                                <img 
                                    src={ReactLogo}
                                    className='image_smallSquare'
                                    alt='React Logo'></img>
                                <div 
                                    className={this.state.logo8Active}></div>
                            </div>
                            <div 
                                className='divAnimationContainer'>
                                <div 
                                    className='devicons devicons-html5 dIconsImage_smallSquare'></div>
                                <div
                                    className={this.state.logo9Active}></div>
                            </div>
                        </div>
                    </div>
                    <div>
                    
                    </div>
                </div>
            </div>
        )
    }
}

export default SkillsBlurb;