var React = require('react')


class ExperienceCard extends React.Component{

    render(){
        return(
            <div 
                id='experienceCard' 
                className = 'container color-background_secondary padding_vertical50'>
                <button 
                    onClick={() => this.props.openLink(this.props.Link)}
                    className='hoverButtonLight flex-row margin_horizontal6 color-background_primary rcorners10 padding_vertical50 padding_horizontal4' 
                    style={{border: '0px'}}>
                    <div 
                        style={{flex:3}}
                        className='margin_vertical25 smallVanish'>
                        <img 
                            src = {this.props.Logo}
                            className='image_largeSquare'
                            alt={this.props.altText}></img>
                    </div>
                    <div 
                        className='flex-column flex_spacebetween flex_start' 
                        style={{flex:7}}>
                            <div
                                className='flex-row flex_center'>
                                <img
                                    src = {this.props.Logo}
                                    className='image_SmallShow'
                                    alt={this.props.altText}>
                                </img>
                                <div
                                    className='flex-column'>
                                    <h1 
                                        className='margin_vertical25' 
                                        style={{marginTop:0}}>{this.props.Company}</h1>
                                    <div 
                                        className='color-dominant padding10 color-text_light rcorners5'
                                        style={{width:'fit-content', whiteSpace:'nowrap'}}>
                                        <h2>
                                            {this.props.Title}</h2>
                                </div>
                            </div>
                        </div>
                        <p 
                            className='margin_vertical25'>
                            {this.props.jobBlurb}</p>
                        <div 
                            id='usedSkills'
                            className='flex-row jobSkillRow'
                            style={{flexWrap:'wrap',width:'100%'}}>
                            {this.props.Skills.map((value, index) => {return(
                                <div 
                                    className = 'jobSkill' 
                                    style={{ marginRight:'3.5%'}}
                                    key={index}>
                                    <p>{value}</p>
                                </div>)})}
                        </div>
                    </div>
                </button>
            </div>
        )
    }
}

export default ExperienceCard;